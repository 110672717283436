import React from "react";
import { Button, Grid, TextField } from "@mui/material";
import DrawerAppBar from "../components/DrawerAppBar";
import { Typography } from "@material-ui/core";

const PoliciesAndPrivacy = () => {
  return (
    <DrawerAppBar>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={10} sm={6}>
          <Typography
            variant="h4"
            style={{
              marginBottom: "20px",
            }}
          >
            Policas e privacidade
          </Typography>
          <TextField
            label="Título"
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Descrição"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
          <Button variant="contained">Salvar</Button>
        </Grid>
      </Grid>
    </DrawerAppBar>
  );
};

export default PoliciesAndPrivacy;
