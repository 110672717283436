import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  Collapse,
  Box,
  TablePagination,
} from "@material-ui/core";
import Skeleton from "@mui/material/Skeleton";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EmailIcon from "@mui/icons-material/Email";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Spinner } from "react-bootstrap";
import NetworkManager from "../Network/NetworkManager";
import { Stack } from "@mui/material";

function OrganizationsTable({
  organizations,
  isLoading,
  onPreview,
  onSendEmail,
  isEmailSending,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openOrgs, setOpenOrgs] = useState({});
  const [openSales, setOpenSales] = useState({});
  const [organizationSales, setOrganizationSales] = useState({});
  const [open, setOpen] = useState({});
  const [totalSales, setTotalSales] = useState(0);

  const networkManager = new NetworkManager();

  useEffect(() => {
    const fetchSalesReport = async () => {
      const expandedOrgs = Object.keys(openOrgs).filter((key) => openOrgs[key]);
      const salesPromises = expandedOrgs.map(async (id) => {
        try {
          const response = await networkManager.fetchSalesReport(
            id,
            localStorage.getItem("env")
          );
          return { [id]: response };
        } catch (error) {
          return { [id]: "error" };
        }
      });

      const results = await Promise.all(salesPromises);
      const salesData = results.reduce(
        (acc, curr) => ({ ...acc, ...curr }),
        {}
      );
      setOrganizationSales((prevSales) => ({ ...prevSales, ...salesData }));
    };

    if (Object.keys(openOrgs).some((key) => openOrgs[key])) {
      fetchSalesReport();
    }
  }, [openOrgs]);

  const handleOrgClick = (id) => {
    setOpenOrgs((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleSaleClick = (orgId, saleIndex) => {
    const key = `${orgId}-${saleIndex}`;
    setOpenSales((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (id) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [id]: !prevOpen[id],
    }));
  };

  const calculateTotalSales = (sales) => {
    const total =
      sales == undefined
        ? "0"
        : sales
            .reduce(
              (total, sale) => total + parseFloat(sale.preco_pagar || 0),
              0
            )
            .toFixed(2);

    setTotalSales(total);
    return total;
  };

  return (
    <React.Fragment>
      <TableContainer
        component={Paper}
        style={{ marginTop: "20px", boxShadow: "0px 4px 12px rgba(0,0,0,0.1)" }}
      >
        <Table aria-label="collapsible table">
          <TableBody>
            {isLoading
              ? [...Array(rowsPerPage)].map((_, index) => (
                  <TableRow key={index}>
                    <TableCell colSpan={6}>
                      <Skeleton variant="text" />
                      <Skeleton variant="circular" width={40} height={40} />
                      <Skeleton
                        variant="rectangular"
                        width={210}
                        height={118}
                      />
                    </TableCell>
                  </TableRow>
                ))
              : organizations
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((organization) => (
                    <React.Fragment key={organization.id}>
                      <TableRow
                        style={{
                          backgroundColor:
                            organization.id % 2 ? "#f7f7f7" : "white",
                          cursor: "pointer",
                          alignItems: "center",
                        }}
                      >
                        <Stack display="flex" flexDirection="row">
                          <TableCell
                            style={{
                              border: "none",
                            }}
                          >
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => handleOrgClick(organization.id)}
                            >
                              {openOrgs[organization.id] ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            style={{
                              border: "none",
                            }}
                          >
                            <Typography variant="subtitle1">
                              {organization.nome}
                            </Typography>
                          </TableCell>
                        </Stack>

                        <TableCell
                          align="right"
                          style={{
                            border: "none",
                          }}
                        >
                          <Tooltip title="Visualizar">
                            <IconButton
                              color="primary"
                              onClick={() => onPreview(organization)}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Enviar Email">
                            <IconButton
                              color="secondary"
                              onClick={() =>
                                onSendEmail(
                                  organization.email,
                                  organization.id,
                                  organization.nome
                                )
                              }
                              disabled={isEmailSending}
                            >
                              {isEmailSending ? (
                                <Spinner animation="border" size="sm" />
                              ) : (
                                <EmailIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                          <Link to={`/organization/${organization.id}`}>
                            <Tooltip title="Detalhes">
                              <IconButton color="default">
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </Link>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse
                            in={openOrgs[organization.id]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box margin={1}>
                              {organizationSales[organization.id] &&
                              Array.isArray(
                                organizationSales[organization.id]
                              ) &&
                              organizationSales[organization.id].length > 0 ? (
                                organizationSales[organization.id].map(
                                  (sale, index) => (
                                    <React.Fragment key={index}>
                                      <Stack
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        paddingLeft={5}
                                      >
                                        <IconButton
                                          aria-label="expand sale detail"
                                          size="small"
                                          onClick={() =>
                                            handleSaleClick(
                                              organization.id,
                                              index
                                            )
                                          }
                                        >
                                          {/* {openSales[
                                            `${organization.id}-${index}`
                                          ] ? (
                                            <KeyboardArrowUpIcon />
                                          ) : (
                                            <KeyboardArrowDownIcon />
                                          )} */}
                                        </IconButton>
                                        <Typography
                                          variant="subtitle2"
                                          display="inline"
                                          alignItems="center"
                                        >
                                          Referência: {sale.referencia}
                                        </Typography>
                                      </Stack>

                                      <Box
                                        in={
                                          openSales[
                                            `${organization.id}-${index}`
                                          ]
                                        }
                                        timeout="auto"
                                        unmountOnExit
                                        style={{
                                          paddingLeft: "55px",
                                        }}
                                      >
                                        <Table
                                          size="small"
                                          aria-label={`Detalhes da venda ${
                                            index + 1
                                          }`}
                                        >
                                          <TableBody>
                                            <TableRow>
                                              <TableCell>
                                                Data da Compra
                                              </TableCell>
                                              <TableCell>
                                                {sale.databuy}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell>Título</TableCell>
                                              <TableCell>
                                                {sale.titulo || sale.edicao}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell>
                                                Preço a Pagar
                                              </TableCell>
                                              <TableCell>
                                                {sale.preco_pagar}.00 Kz
                                              </TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </Box>
                                    </React.Fragment>
                                  )
                                )
                              ) : (
                                <Typography variant="body2">
                                  Nenhum dado encontrado
                                </Typography>
                              )}
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={organizations.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
}

export { OrganizationsTable };
