import React, { useState, useEffect } from "react";
import {
  Button,
  List,
  ListItem,
  Typography,
  useTheme,
  Card,
  CardContent,
  Tabs,
  Tab,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const authorsData = [
  {
    id: 1,
    name: "Autor A",
    genre: "Ficção Científica",
    booksPublished: 5,
  },
  {
    id: 2,
    name: "Autor B",
    genre: "História",
    booksPublished: 3,
  },
  {
    id: 3,
    name: "Autor C",
    genre: "Biografias",
    booksPublished: 2,
  },
];

const suppliersData = [
  {
    id: 1,
    name: "Alpha Books",
    description: "Especializada em materiais acadêmicos e educacionais.",
    contract: "000/24",
  },
  {
    id: 2,
    name: "Beta Bookstore",
    description: "Ampla gama de livros de ficção e não ficção.",
    contract: "001/24",
  },
  {
    id: 3,
    name: "Gamma Publications",
    description: "Editora de livros de ciência e tecnologia populares.",
    contract: "002/24",
  },
  {
    id: 4,
    name: "Delta Publishing",
    description: "Editora de livros de historias e biografias.",
    contract: "003/24",
  },
];

export function ContractsList() {
  const [suppliers, setSuppliers] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleRouter = async (id) => {
    navigate(`/supplier/${id}`);
  };

  useEffect(() => {
    setSuppliers(suppliersData);
    setAuthors(authorsData);
  }, []);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Stack marginTop={theme.spacing(8)}>
      <Tabs value={tabValue} onChange={handleChangeTab} centered>
        <Tab label="Contrato de Editores" />
        <Tab label="Contrato de Autores" />
      </Tabs>

      {tabValue === 0 && (
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            marginTop: theme.spacing(7),
          }}
        >
          <Button>Novo Contrato de Editor</Button>
          {suppliers.map((supplier, index) => (
            <ListItem
              key={index}
              style={{ padding: theme.spacing(1) }}
              component="div"
              disablePadding
            >
              <Card
                variant="outlined"
                sx={{ width: "100%" }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: theme.spacing(1),
                }}
              >
                <CardContent>
                  <Typography variant="h6" component="div">
                    {supplier.name}
                  </Typography>
                  <Typography variant="body2">
                    {supplier.description}
                  </Typography>
                  <Typography variant="body2">{supplier.contract}</Typography>
                </CardContent>

                <Button onClick={() => handleRouter(supplier.name)}>
                  Detalhes
                </Button>
              </Card>
            </ListItem>
          ))}
        </List>
      )}

      {tabValue === 1 && (
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            marginTop: theme.spacing(7),
          }}
        >
          <Button>Novo Contrato de Autor</Button>
          {authors.map((author, index) => (
            <ListItem
              key={index}
              style={{ padding: theme.spacing(1) }}
              component="div"
              disablePadding
            >
              <Card
                variant="outlined"
                sx={{ width: "100%" }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: theme.spacing(1),
                }}
              >
                <CardContent>
                  <Typography variant="h6" component="div">
                    {author.name}
                  </Typography>
                </CardContent>
                <Button onClick={() => handleRouter(author.name)}>
                  Detalhes
                </Button>
              </Card>
            </ListItem>
          ))}
        </List>
      )}
    </Stack>
  );
}
