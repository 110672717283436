import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  List,
  ListItem,
  Typography,
  useTheme,
  Card,
  CardContent,
  TextField,
  Skeleton,
  Stack,
  Modal,
  Box,
  Slider,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Delete, DiscountOutlined, Edit, Lock } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import BookEditModal from "../components/BookEditModal";
import NetworkManager from "../Network/NetworkManager";
import Cookies from "js-cookie";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ToastContainer, toast } from "react-toastify";

export function PublicationsList() {
  const [publications, setPublications] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedBook, setSelectedBook] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const [discountValue, setDiscountValue] = useState(0);
  const [error, setError] = useState("");

  const [idDiscont, setidDiscont] = useState("");

  const [discountStartDate, setDiscountStartDate] = useState(dayjs());
  const [discountEndDate, setDiscountEndDate] = useState(dayjs());

  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const itemsPerPage = 30;
  const theme = useTheme();
  const navigate = useNavigate();

  const containerRef = useRef(null);

  const networkManager = new NetworkManager();

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await networkManager.getBooksByViews();

        if (data && Array.isArray(data)) {
          setPublications(data);
        } else {
          logout();
          console.log("Os dados da resposta são inválidos:", data);
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setError("Falha ao carregar os dados. Tente novamente.");
        logout();
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (
        containerRef.current &&
        containerRef.current.scrollTop + containerRef.current.clientHeight >=
          containerRef.current.scrollHeight - 10
      ) {
        loadMoreItems();
      }
    };

    containerRef.current.addEventListener("scroll", handleScroll);

    return () => {
      containerRef.current.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const loadMoreItems = async () => {
    setLoading(true);
    setTimeout(async () => {
      try {
        const newData = await networkManager.getMoreBooks();

        if (newData && Array.isArray(newData)) {
          setPublications((prevPublications) => [
            ...prevPublications,
            ...newData,
          ]);
        } else {
          console.log("Os dados da resposta são inválidos:", newData);
        }
      } catch (error) {
        console.error("Erro ao buscar mais dados:", error);
      } finally {
        setLoading(false);
      }
    }, 2000);
  };

  const handleRouter = async (route) => {
    navigate(route);
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
  };

  const handleEditBook = (book) => {
    setSelectedBook(book);
    setIsModalOpen(true);
    setModalType("edit");
  };

  const handleDeleteBook = (book) => {
    setSelectedBook(book);
    setIsModalOpen(true);
    setModalType("delete");
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedBook(null);
    setModalType(null);
  };

  const handleApplyDiscount = async () => {
    const editedBook = {
      id: idDiscont,
      discountValue: discountValue,
      discountStartDate: discountStartDate.format("YYYY-MM-DD"),
      discountEndDate: discountEndDate.format("YYYY-MM-DD"),
    };

    try {
      await networkManager
        .addDiscount(editedBook)
        .then(toast.success("Desconto aplicado com sucesso!"))
        .catch((error) => {
          console.error("Erro ao aplicar o desconto:", error);
          toast.error("Erro ao aplicar o desconto!");
        });
      handleCloseDiscountModal();
    } catch (error) {
      console.error(
        "Falha ao comunicar com o servidor para aplicar o desconto:",
        error
      );
      handleCloseDiscountModal();
    }
  };

  const handleSaveEditedBook = (editedBook) => {
    const updatedPublications = publications.map((book) =>
      book.id === editedBook.id ? editedBook : book
    );

    networkManager.editBook(editedBook);

    setPublications(updatedPublications);
    handleCloseModal();
  };

  const handleOpenDiscountModal = (id) => {
    setIsDiscountModalOpen(true);
    setidDiscont(id);
  };

  const handleActivar = async (id) => {
    const editbook = await networkManager.activateBook(id);
    if (editbook) {
      const updatedPublications = publications.map((book) =>
        book.id === editbook.id ? editbook : book
      );
      setPublications(updatedPublications);
    }
  };

  const handleCloseDiscountModal = () => {
    setIsDiscountModalOpen(false);
  };

  const handleDeleteBookConfirm = async (password) => {
    const AUTH = Cookies.get("AUTH");
    if (password !== AUTH) {
      toast.error("Senha inválida!");
      return;
    }
    const deletedBook = await networkManager.deleteBook(selectedBook.id);
    if (deletedBook) {
      const updatedPublications = publications.filter(
        (book) => book.id !== deletedBook.id
      );
      toast.success("Livro excluído com sucesso!");
      setPublications(updatedPublications);
    }
    handleCloseModal();
  };
  const filteredPublications = publications.filter(
    (publication) =>
      publication.titulo.toLowerCase().includes(searchTerm) ||
      publication.escritor.toLowerCase().includes(searchTerm)
  );

  return (
    <div style={{ marginTop: "80px" }} ref={containerRef}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Lista de Livros na Plataforma</Typography>
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: "5px" }}
          onClick={() => navigate("/books")}
        >
          Novo Livro
        </Button>
      </Stack>
      {error && (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      )}
      <TextField
        label="Pesquisar por autor ou título"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearch}
        fullWidth
        margin="normal"
      />

      {loading ? (
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            marginTop: theme.spacing(1),
          }}
        >
          {[...Array(itemsPerPage)].map((_, index) => (
            <ListItem
              key={index}
              style={{ padding: theme.spacing(1) }}
              component="div"
              disablePadding
            >
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height={118}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            marginTop: theme.spacing(1),
          }}
        >
          {filteredPublications.map((publication) => (
            <ListItem
              key={publication.id}
              style={{ padding: theme.spacing(1) }}
              component="div"
              disablePadding
            >
              <Card
                variant="outlined"
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: theme.spacing(1),
                }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: theme.spacing(3),
                  }}
                >
                  <LazyLoadImage
                    src={publication.capa}
                    alt={publication.titulo}
                    effect="blur"
                    style={{ objectFit: "cover" }}
                    placeholderSrc={publication.capa}
                    width={60}
                    height={80}
                  />
                  <Stack>
                    <Typography variant="h6" component="div">
                      {publication.titulo}
                    </Typography>

                    <Typography variant="body2">
                      Preço:{" "}
                      {publication.preco === "0"
                        ? "Gratuito"
                        : publication.preco + ".00 Kz"}
                    </Typography>
                  </Stack>
                </CardContent>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button
                    variant="text"
                    color="info"
                    startIcon={
                      publication.visivel == "1" ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )
                    }
                    onClick={(e) => handleActivar(publication.id)}
                  >
                    {publication.visivel == "1" ? "Desactivar" : "Activar"}
                  </Button>
                  <Button
                    variant="text"
                    color="secondary"
                    startIcon={<DiscountOutlined />}
                    style={{ marginLeft: "5px" }}
                    onClick={() => handleOpenDiscountModal(publication.id)}
                  >
                    Adicionar Desconto
                  </Button>
                  <Button
                    startIcon={<Edit />}
                    onClick={() => handleEditBook(publication)}
                  >
                    Editar
                  </Button>
                  <Button
                    startIcon={<Delete />}
                    onClick={() => handleDeleteBook(publication)}
                  >
                    Eliminar livro
                  </Button>
                </Stack>
              </Card>
            </ListItem>
          ))}
        </List>
      )}
      {isModalOpen && modalType === "edit" && (
        <BookEditModal
          open={isModalOpen}
          onClose={handleCloseModal}
          bookDetails={selectedBook}
          onSave={handleSaveEditedBook}
        />
      )}

      {isModalOpen && modalType === "delete" && (
        <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
          aria-labelledby="delete-modal-title"
          aria-describedby="delete-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="delete-modal-title" variant="h6" component="h2">
              Apagar livro
            </Typography>
            <Typography id="delete-modal-description" sx={{ mt: 2 }}>
              Voce realmente quer eliminar o livro:
              <Typography fontWeight="bold">{selectedBook.titulo}?</Typography>
            </Typography>

            <Stack gap="10px">
              <TextField
                id="outlined-basic"
                label="Password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                        aria-label="toggle password visibility"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                style={{
                  marginTop: "20px",
                }}
              />
              <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
                <Button
                  onClick={() => handleCloseModal()}
                  color="primary"
                  sx={{ mr: 2 }}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={() => handleDeleteBookConfirm(password)}
                  color="error"
                  variant="contained"
                >
                  Apagar
                </Button>
              </Box>
            </Stack>
          </Box>
        </Modal>
      )}

      <Modal
        open={isDiscountModalOpen}
        onClose={handleCloseDiscountModal}
        aria-labelledby="discount-modal-title"
        aria-describedby="discount-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            maxWidth: "100%",
            spacing: 4,
            margin: "auto",
            bgcolor: "background.paper",
            borderRadius: "12px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="discount-modal-title" variant="h6" component="h2">
            Adicionar Desconto
          </Typography>
          <Slider
            aria-label="Desconto"
            defaultValue={0}
            valueLabelDisplay="auto"
            step={5}
            marks
            min={0}
            max={100}
            onChange={(event, value) => setDiscountValue(value)}
          />
          <Typography variant="body1" marginTop="10px" fontWeight="bold">
            Desconto: {discountValue}%
          </Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction="row" spacing={2} marginTop="30px">
              <DatePicker
                label="Data de Início do Desconto"
                value={discountStartDate}
                onChange={(newValue) => {
                  setDiscountStartDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="Data de Fim do Desconto"
                value={discountEndDate}
                onChange={(newValue) => {
                  setDiscountEndDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: "20px" }}
            onClick={() => {
              handleApplyDiscount();
            }}
          >
            Aplicar Desconto
          </Button>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
}
