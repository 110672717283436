import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";
import InsertBookForm from "../components/InsertBookForm";
import DrawerAppBar from "../components/DrawerAppBar";

export default function Books() {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <DrawerAppBar>
        <InsertBookForm />
      </DrawerAppBar>
    </Box>
  );
}
