import React from "react";
import DrawerAppBar from "../components/DrawerAppBar";
import { SubscribersList } from "../components/SubscriptionsList";

const Subscription = () => {
  return (
    <DrawerAppBar>
      <SubscribersList />
    </DrawerAppBar>
  );
};

export default Subscription;
