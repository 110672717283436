import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { BsFilePdf, BsImageAlt, BsThreeDotsVertical } from "react-icons/bs";
import Button from "@mui/material/Button";
import Dropzone from "react-dropzone";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { SiMicrosoftsharepoint } from "react-icons/si";
import Grid from "@mui/material/Grid";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Divider,
  Stack,
  Box,
  Modal,
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Popover,
  Input,
  Alert,
  Breadcrumbs,
} from "@mui/material";
import { Spinner } from "react-bootstrap";
import NetworkManager from "../Network/NetworkManager";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast, useToast } from "react-toastify";
import {
  ArrowBack,
  ArrowForward,
  Book,
  BookOnlineOutlined,
  Bookmark,
  CheckCircleSharp,
  ClearOutlined,
  FolderOutlined,
  ImageRounded,
  InsertDriveFileOutlined,
  Label,
  RefreshOutlined,
  RefreshRounded,
} from "@mui/icons-material";
import { SharePointManager } from "../services/sharepoint_integration";
import { set } from "react-hook-form";

const networkManager = new NetworkManager();

const InsertBookForm = () => {
  const [formData, setFormData] = useState({
    titulo: "",
    preco: 0,
    descricao: "",
    autor: "",
    categoria: "",
    subcate: "",
    capa: "",
    desenhista: "",
    escritor: "",
    ISBN: "",
    edicao: "",
    periodicidade: "",
    numContrato: "010/21",
    Royalties: "",
    subsid: "",
    srcList: "",
    totalpage: "",
    size: "0BK",
    file: "",
    UID: "",
  });

  const [numContratos, setNumContratos] = useState([]);
  const [categoriaBook, setCategoriaBook] = useState([
    "Livros",
    "Revista",
    "BD",
    "Jornais",
  ]);
  const [pdfOrEpubFile, setPdfOrEpubFile] = useState(null);
  const [jpgFiles, setJpgFiles] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadProgressImage, setUploadProgressImage] = useState(0);
  const [sharepointFolders, setSharepointFolders] = useState([]);
  const [sharepointFiles, setSharepointFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sharePointModalOpen, setSharePointModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(""); // Estado para controlar a opção selecionada
  const [folderPath, setFolderPath] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openAddBookModal, setOpenAddBookModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cover, setCover] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState("");
  const [isSelectedImage, setIsSelectedImage] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [open, setOpen] = React.useState(false);

  const sharepoint = new SharePointManager();

  const handleGetPublications = async () => {
    setLoading(true);
    await sharepoint
      .getPublications()
      .then((data) => setSharepointFolders(data))
      .finally(() => setLoading(false));
  };

  const handleGetFSharepointFiles = async (path_name) => {
    if (path_name) {
      await sharepoint.getFiles(path_name).then((data) => {
        setSharepointFiles(data);
        console.log(data);
      });
    }
  };

  useEffect(() => {
    handleGetPublications();
    handleGetFSharepointFiles();
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenAddBookModal = () => {
    setOpenAddBookModal(true);
  };

  // Create a function to handle closing the modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseAddBookModal = () => {
    setOpenAddBookModal(false);
  };

  useEffect(() => {
    getallNumContratos();
    // handleGetFoldersOnSharePoint();
  }, []);

  const getallNumContratos = async () => {
    const result = await networkManager.getAllNumContracts({});
    if (result) setNumContratos(result);
  };

  const handleSharePointDrop = async (acceptedFiles) => {
    // Lógica para lidar com o upload de arquivos do SharePoint
    setSelectedOption("Sharepoint");
    setSharePointModalOpen(true);
  };

  const handleCloseSharePointModal = () => {
    setSharePointModalOpen(false);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSelectFolder = async (folder, serverRelativeUrl, files) => {
    setSharepointFolders(files);

    // await sharepoint.getFolders(folder).then((data) => {
    //   setSharepointFolders(data);
    //   handleGetFSharepointFiles(serverRelativeUrl);
    // });
  };

  const handleSelectPdf = async (file_path) => {
    setSelectedFile(file_path);
    toast.success("PDF selecionado: " + file_path);

    localStorage.setItem("pdf_path", file_path);

    handleDownloadFile(file_path).then((data) => {
      console.log(data);
    });
  };

  const handleSelectImage = async (file_path) => {
    setCover(file_path);
    toast.success("Imagem selecionada: " + file_path);
    console.log("Arquivo de imagem selecionado: " + file_path);
  };

  const handleClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const getallNumContractos = async () => {
    const result = await networkManager.getAllNumContracts({});
    if (result) setNumContratos(result);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePdfOrEpubDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];

    setPdfOrEpubFile(file);
    setUploadProgress(0);

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("title", formData.titulo);

      const response = await networkManager.insertSingleBook(formData, {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setUploadProgress(progress);
        },
      });

      console.log("File uploaded successfully:", response);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleJpgDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];

    setJpgFiles(file);

    try {
      const formData = new FormData();
      // files.forEach((file, index) => {
      //   formData.append(`file${index}`, file);
      // });
      formData.append("file", file);
      formData.append("title", formData.titulo);
      const response = await networkManager.insertSingleBook(formData, {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setUploadProgressImage(progress);
        },
      });

      if (response.file_path !== undefined || response.file_path !== null) {
        setIsSelectedImage(true);
      }

      console.log("Files uploaded successfully:", response);
      toast.success("Imagem selecionada com sucesso!");
      setFormData({ ...formData, ["capa"]: response.file_path });
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const handleSubmit = async () => {
    setIsSubmit(true);

    const file = localStorage.getItem("file_pdf");

    try {
      await networkManager
        .InsertBookForm({
          ...formData,
          titulo: formData.titulo,
          preco: formData.preco,
          descricao: formData.descricao,
          capa: formData.capa,
          Royalties: formData.Royalties,
          categoria: formData.categoria,
          subcate: formData.subcate,
          desenhista: formData.desenhista,
          num_contrato: formData.numContrato,
          editora: formData.editora,
          status: formData.status,
          periodicidade: formData.periodicidade,
          file: formData.file,
          totalpage: formData.totalpage,
          UID: formData.UID,
          size: "10MB",
        })
        .then(() => {
          toast.success("Livro criado com sucesso!");
        });

      handleResetTable();
    } catch (error) {
      console.log(error);
      toast.error("Erro ao criar o livro");
    } finally {
      setIsSubmit(false);
    }

    console.log("Form data submitted:", formData);
    console.log("PDF or EPUB file:", pdfOrEpubFile);
    console.log("JPG files:", jpgFiles);
  };

  const handleRoyaltiesChange = (event, value) => {
    setFormData({
      ...formData,
      Royalties: value,
    });
  };

  const handleDownloadFile = async (file_path) => {
    setDownloading(true);
    try {
      await sharepoint
        .downloadFile(file_path)
        .then((data) => {
          const { urlPath } = data.file_path;

          console.log(data);

          if (urlPath === undefined) {
            localStorage.setItem("file_cover", data.file_path);
            setCover(data.file_path);
            console.log("Arquivo de capa selecionado: " + data.file_path);
          } else {
            localStorage.setItem("file_pdf", urlPath);
            setSelectedFile(urlPath);
            console.log("Arquivo selecionado: " + urlPath);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setDownloading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleResetTable = () => {
    setSharepointFolders([]);
    setSharepointFiles([]);
    localStorage.removeItem("file_cover");
    localStorage.removeItem("file_pdf");
    setCover(null);
    setSelectedFile(null);
    handleGetPublications();
  };

  const isImageOrPdf = (url) => {
    const extension = url.toLowerCase().slice(-4);
    console.log(extension);
    return extension;
  };

  return (
    <div style={{ padding: "20px", marginTop: "40px", width: "100%" }}>
      <Typography variant="h5" component="h2">
        Selecione o tipo de Integração para criar o livro
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {/* Renderize o Select para escolher entre Sharepoint e Tradicional */}
            <FormControl margin="normal" variant="outlined" width="500px">
              <InputLabel id="select-label">Tipo de Integração</InputLabel>
              <Select
                labelId="select-label"
                id="select-option"
                value={selectedOption}
                defaultValue="Sharepoint"
                onChange={handleOptionChange}
                fullWidth
                label="Tipo de Integração"
                style={{ width: "400px" }}
              >
                <MenuItem
                  value="Sharepoint"
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <SiMicrosoftsharepoint /> Sharepoint
                </MenuItem>
                <MenuItem
                  value="Tradicional"
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <Bookmark /> Tradicional
                </MenuItem>
              </Select>
            </FormControl>
            {/* Renderize os campos conforme a opção selecionada */}
            {selectedOption === "Tradicional" ? (
              <form onSubmit={handleSubmit}>
                <Typography component="h3">Criação de Livros</Typography>

                <Divider />

                {isSelectedImage === true ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        placeholder="UID"
                        name="UID"
                        onChange={(e) => {
                          setFormData({ ...formData, UID: e.target.value });
                        }}
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        placeholder="Title"
                        name="titulo"
                        onChange={(e) =>
                          setFormData({ ...formData, titulo: e.target.value })
                        }
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        placeholder="Price"
                        name="preco"
                        onChange={(e) =>
                          setFormData({ ...formData, preco: e.target.value })
                        }
                        fullWidth
                        margin="normal"
                      />
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="categoria-label">Category</InputLabel>
                        <Select
                          labelId="categoria-label"
                          id="categoria-select"
                          placeholder="Category"
                          name="categoria"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              categoria: e.target.value,
                            })
                          }
                          fullWidth
                          margin="normal"
                        >
                          {categoriaBook.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        placeholder="Author"
                        name="autor"
                        onChange={(e) =>
                          setFormData({ ...formData, autor: e.target.value })
                        }
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        placeholder="Total de paginas"
                        name="totalpage"
                        type="number"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            totalpage: e.target.value,
                          });
                        }}
                        fullWidth
                        margin="normal"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        placeholder="Description"
                        name="descricao"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            descricao: e.target.value,
                          })
                        }
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                      />
                      <TextField
                        placeholder="Subcategory"
                        name="subcate"
                        onChange={(e) =>
                          setFormData({ ...formData, subcate: e.target.value })
                        }
                        fullWidth
                        margin="normal"
                      />

                      {formData.categoria === "Revistas" ||
                      formData.categoria === "BD" ? (
                        <TextField
                          placeholder="Artist"
                          name="desenhista"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              desenhista: e.target.value,
                            })
                          }
                          fullWidth
                          margin="normal"
                        />
                      ) : null}

                      {formData.categoria === "Livros" ? (
                        <TextField
                          placeholder="ISBN"
                          name="ISBN"
                          onChange={(e) =>
                            setFormData({ ...formData, ISBN: e.target.value })
                          }
                          fullWidth
                          margin="normal"
                        />
                      ) : null}

                      <TextField
                        placeholder="Edition"
                        name="edicao"
                        onChange={(e) =>
                          setFormData({ ...formData, edicao: e.target.value })
                        }
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        placeholder="Periodicity"
                        name="periodicidade"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            periodicidade: e.target.value,
                          });
                        }}
                        fullWidth
                        margin="normal"
                      />

                      <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                          <InputLabel
                            style={{ marginTop: "30px" }}
                            id="demo-simple-select-label"
                          >
                            Num Contrato
                          </InputLabel>
                          <Select
                            style={{ marginTop: "15px" }}
                            name="numContrato"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                numContrato: e.target.value,
                              });
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            fullWidth
                            margin="normal"
                          >
                            {Array.isArray(numContratos) &&
                              numContratos.map((item, index) => (
                                <MenuItem key={index} value={item.num}>
                                  <strong>{item.num}</strong> - {item.nome}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <div style={{ padding: 10, width: "100%" }}>
                      <Typography gutterBottom>
                        Royalties: {formData.Royalties}%
                      </Typography>
                      <Slider
                        value={formData.Royalties}
                        onChange={handleRoyaltiesChange}
                        valueLabelDisplay="auto"
                        valueLabelFormat={(value) => `${value}%`}
                        min={0}
                        max={100}
                      />
                    </div>

                    <hr />

                    <Grid container spacing={2} className="mt-3">
                      <Grid item xs={12}>
                        {pdfOrEpubFile && (
                          <div className="d-flex justify-content-between align-items-center border-danger border-bottom">
                            <div className="d-flex align-items-center gap-5">
                              <IconButton aria-label="delete">
                                <CloseIcon />
                              </IconButton>
                              <div className="p-2">{pdfOrEpubFile.name}</div>
                            </div>
                            <div className="d-flex align-items-center gap-5">
                              <progress
                                value={uploadProgress}
                                max="100"
                              ></progress>
                              <div>{uploadProgress.toFixed(0)}%</div>
                            </div>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Dropzone onDrop={handleJpgDrop} accept=".jpg">
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            className="dropzone"
                            style={{
                              width: "500px",
                              height: "300px",
                              border: "2px dashed #ccc",
                              marginTop: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <input {...getInputProps()} />
                            <CloudUploadIcon fontSize="large" />
                            <p>
                              Clique ou arraste para carregar uma capa
                              <p className="text-danger">
                                Carregue a capa primeiro e depois preencha os
                                demais campos
                              </p>
                            </p>
                          </div>
                        )}
                      </Dropzone>
                    </Grid>
                    <Grid item xs={12}>
                      {jpgFiles && (
                        <div
                          className="d-flex justify-content-between align-items-center border-danger border-bottom"
                          key={jpgFiles.name}
                        >
                          <div className="d-flex align-items-center gap-5">
                            <IconButton aria-label="delete">
                              <CloseIcon />
                            </IconButton>
                            <div className="p-2">{jpgFiles.name}</div>
                          </div>
                          <div className="d-flex align-items-center gap-5">
                            <progress
                              value={uploadProgressImage}
                              max="100"
                            ></progress>
                            <div>{uploadProgressImage.toFixed(0)}%</div>
                          </div>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                )}

                <Button
                  type="submit"
                  className="p-3 w-40"
                  variant="contained"
                  color="primary"
                  style={{
                    position: "relative",
                    marginTop: "20px",
                  }}
                  disabled={isSubmit}
                  onClick={() => handleSubmit()}
                >
                  {isSubmit ? (
                    <Spinner animation="border" />
                  ) : (
                    <strong>Adicionar a Obra</strong>
                  )}
                </Button>
              </form>
            ) : (
              <Stack width="100%" justifyContent="center">
                {cover && cover ? (
                  <Typography display="flex" alignItems="center" gap={1}>
                    Capa Selecionada:
                    <Typography
                      fontWeight="bold"
                      color="green"
                      display="flex"
                      flexDirection={"row"}
                      alignItems="center"
                      gap={1}
                    >
                      {" "}
                      {cover.split("/").pop()}{" "}
                      {downloading ? (
                        <Spinner color="blue" size="small" />
                      ) : (
                        <CheckCircleSharp color="green" />
                      )}
                    </Typography>
                  </Typography>
                ) : null}

                {selectedFile && selectedFile ? (
                  <Typography display="flex" alignItems="center" gap={1}>
                    Arquivo Selecionado:
                    <Typography
                      fontWeight="bold"
                      color="green"
                      display="flex"
                      gap={1}
                      flexDirection={"row"}
                      alignItems="center"
                    >
                      {selectedFile.split("/").pop()}{" "}
                      {downloading ? (
                        <Spinner color="blue" size="small" />
                      ) : (
                        <CheckCircleSharp color="green" />
                      )}
                    </Typography>
                  </Typography>
                ) : null}
                <TableContainer
                  component={Paper}
                  sx={{ mt: 2 }}
                  variant="outlined"
                  style={{ overflowX: "auto", width: "1000px" }}
                >
                  <Table
                    aria-label="collapsible table"
                    size="small"
                    sx={{
                      mt: 2,
                      width: "100%",
                      marginLeft: "10px",
                    }}
                  >
                    <TableHead>
                      <Stack
                        direction="row"
                        display="flex"
                        justifyContent="space-between"
                        width="inherit"
                      >
                        <Stack>
                          <Button
                            onClick={handleOpenAddBookModal}
                            variant="outlined"
                            size="small"
                            startIcon={<Book />}
                            disabled={cover === null || selectedFile === null}
                          >
                            Adicionar livro a biblioteca
                          </Button>
                        </Stack>

                        <Stack direction="row" alignSelf="end">
                          <Button onClick={handleResetTable}>
                            <RefreshOutlined />
                          </Button>

                          <Button
                            onClick={() => {
                              setCover(null);
                              setSelectedFile(null);
                            }}
                          >
                            <ClearOutlined />
                          </Button>

                          <Button
                            onClick={handleGetPublications}
                            variant="outlined"
                            size="small"
                            startIcon={<RefreshRounded />}
                          >
                            Atualizar
                          </Button>
                        </Stack>
                      </Stack>

                      <TableRow>
                        <TableCell width={"30%"}>Nome da Pasta</TableCell>
                        <TableCell
                          style={{
                            display:
                              sharepointFolders.length === 0 ? "none" : "block",
                          }}
                        >
                          Número de Items
                        </TableCell>
                        <TableCell>Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      style={{
                        padding: "10px",
                        width: "100%",
                      }}
                    >
                      {sharepointFolders === null || undefined || loading ? (
                        <Stack
                          width="100%"
                          justifyContent="center"
                          gap={2}
                          display={"flex"}
                        >
                          <Typography>Carregando...</Typography>
                          <Spinner
                            color="blue"
                            size="small"
                            animation="grow"
                            sx={{ mt: 2 }}
                            style={{
                              alignSelf: "center",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />
                        </Stack>
                      ) : (
                        sharepointFolders.map((folder, index) => (
                          <TableRow key={index}>
                            <TableCell width={"30%"}>
                              <Button
                                onClick={() => {
                                  handleSelectFolder(
                                    folder.Name,
                                    folder.ServerRelativeUrl,
                                    folder.files
                                  );
                                }}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "15px",
                                  justifyContent: "flex-start",
                                  width: "30%",
                                }}
                              >
                                <FolderOutlined />
                                {folder.Name}
                              </Button>
                            </TableCell>

                            <TableCell>
                              <Typography>{folder.ItemCount}</Typography>
                            </TableCell>
                            <TableCell>
                              <Button>
                                <Stack
                                  padding={2}
                                  direction="row"
                                  gap={2}
                                  justifyContent="start"
                                >
                                  <Button
                                    onClick={() => {
                                      if (
                                        isImageOrPdf(folder.ServerRelativeUrl)
                                      ) {
                                        handleSelectImage(
                                          folder.ServerRelativeUrl
                                        );
                                      } else {
                                        console.error(
                                          "O arquivo não é uma imagem JPG."
                                        );
                                        toast.error(
                                          "O arquivo não é uma imagem JPG."
                                        );
                                      }
                                    }}
                                  >
                                    <ImageRounded />
                                  </Button>

                                  <Button
                                    onClick={() => {
                                      if (
                                        isImageOrPdf(folder.ServerRelativeUrl)
                                      ) {
                                        handleSelectPdf(
                                          folder.ServerRelativeUrl
                                        );
                                      } else {
                                        // Aqui você pode adicionar uma mensagem de erro ou outra lógica
                                        console.error(
                                          "O arquivo não é um PDF."
                                        );

                                        toast.error("O arquivo não é um PDF.");
                                      }
                                    }}
                                  >
                                    <BsFilePdf size={20} />
                                  </Button>
                                </Stack>
                                {error && (
                                  <p style={{ color: "red" }}>{error}</p>
                                )}
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            )}
          </Grid>
        </Grid>
      </form>

      <Modal
        open={openAddBookModal}
        onClose={handleCloseAddBookModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {/* Modal content */}
          <Box
            sx={{
              bgcolor: "background.paper",
              border: "2px solid #000",
              p: 2,
              minWidth: 400,
              height: "max-content",
            }}
          >
            <Typography id="modal-title" variant="h6" component="h2">
              Adicionar Obra
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Titulo da Obra"
                  placeholder="Titulo da Obra"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      titulo: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Descrição da Obra"
                  placeholder="Descricão da Obra"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      descricao: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  label="Categoria"
                  placeholder="Categoria"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      categoria: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  label="Sub-categoria"
                  placeholder="Sub-categoria"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      subcate: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  label="ISBN"
                  placeholder="ISBN"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      ISBN: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  label="Desenhista"
                  placeholder="Desenhista"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      desenhista: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  label="Escritor"
                  placeholder="Escritor"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      escritor: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  label="Edição"
                  placeholder="Edição"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      edicao: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  label="Preço"
                  placeholder="Preço"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      preco: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  label="Periodicidade"
                  placeholder="Periodicidade"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      periodicidade: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormControl fullWidth>
                  <InputLabel>Contratos</InputLabel>
                  <Select
                    value={formData.numContrato}
                    name="numContrato"
                    onChange={(e) =>
                      setFormData({ ...formData, numContrato: e.target.value })
                    }
                    label="Contratos"
                  >
                    {numContratos.map((item, index) => (
                      <MenuItem key={index} value={item.num}>
                        <strong>{item.num}</strong> - {item.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography>Royalities</Typography>
                <Slider
                  value={formData.Royalties}
                  onChange={handleRoyaltiesChange}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => `${value}%`}
                  min={0}
                  max={100}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Button
              sx={{ position: "absolute", bottom: 30, right: 10 }}
              onClick={() => handleSubmit()}
              variant="contained"
              disabled={loading}
            >
              {loading ? <Spinner animation="border" /> : "Adicionar Livro"}
            </Button>
          </Box>
        </div>
      </Modal>

      <ToastContainer />
    </div>
  );
};

export default InsertBookForm;
