import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import PhonelinkLockIcon from "@mui/icons-material/PhonelinkLock";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ListItemText from "@mui/material/ListItemText";
import SummarizeIcon from "@mui/icons-material/Summarize";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PaidIcon from "@mui/icons-material/Paid";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Avatar, Breadcrumbs } from "@material-ui/core";
import { Button, Stack } from "@mui/material";
import logo from "../assets/kioxke-logo.png";
import { UserMenu } from "./Menu";
import { Add, Email, HorizontalRule } from "@mui/icons-material";

const drawerWidth = 260;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function DrawerAppBar({ children }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [user, setUser] = React.useState("");
  const [showMenu, setShowMenu] = React.useState(false);

  const params = window.location.pathname.split("/")[1];
  const routes = params.charAt(0).toUpperCase() + params.slice(1);

  const handleRouter = async (route) => {
    navigate(route);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const HandleLogOut = async () => {
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
  };

  const get_user_data = async () => {
    const user_data = localStorage.getItem("user_data");
    setUser(user_data);
    return user_data;
  };

  React.useEffect(() => {
    get_user_data();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        style={{
          background: "#f2f2f2",
          color: " #000",
        }}
      >
        <Toolbar
          style={{
            alighnItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Stack direction="row" alignItems="center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon color="#f2f2f2" />
            </IconButton>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/home" style={{ textDecoration: "none" }}>
                <Typography underline="hover" color="inherit">
                  Control Tower
                </Typography>
              </Link>
              <Typography color="text.primary">{params}</Typography>
            </Breadcrumbs>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <UserMenu />
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            background: "#f2f2f2",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton
            onClick={handleDrawerClose}
            disableFocusRipple
            disableTouchRipple
            disableRipple
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "20px",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Avatar>
              <img src={logo} alt="logo kioxke" width={40} height={40} />
            </Avatar>
            <Typography fontSize={12}>{user}</Typography>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List>
          <ListItem
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            {/* <ListItemButton
              onClick={() => handleRouter("/vendas")}
              style={{
                alignSelf: "center",
                alignItems: "start",
                width: "100%",
                justifyContent: "start",
                borderRadius: "10px 10px 0px 0px",
              }}
            >
              <ListItemIcon
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <PaymentIcon style={{}} />
                <ListItemText primary="Vendas" />
              </ListItemIcon>
            </ListItemButton> */}

            <ListItemButton
              onClick={() => handleRouter("/suppliers")}
              style={{
                alignSelf: "center",
                alignItems: "start",
                width: "100%",
                justifyContent: "start",
                borderRadius: "10px 10px 0px 0px",
              }}
            >
              <ListItemIcon
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <PeopleAltIcon style={{}} />
                <ListItemText primary="Fornecedores" />
              </ListItemIcon>
            </ListItemButton>

            <ListItemButton
              onClick={() => handleRouter("/subscriptions")}
              style={{
                alignSelf: "center",
                alignItems: "start",
                width: "100%",
                justifyContent: "start",
                borderRadius: "10px 10px 0px 0px",
              }}
            >
              <ListItemIcon
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <GroupAddIcon style={{}} />
                <ListItemText primary="Lista de subscrições" />
              </ListItemIcon>
            </ListItemButton>

            <ListItemButton
              onClick={() => handleRouter("/home")}
              style={{
                alignSelf: "center",
                alignItems: "start",
                width: "100%",
                justifyContent: "start",
                borderRadius: "10px 10px 0px 0px",
              }}
            >
              <ListItemIcon
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <SummarizeIcon style={{}} />
                <ListItemText primary="Relatorios" />
              </ListItemIcon>
            </ListItemButton>
            {/* <ListItemButton
              onClick={() => handleRouter("/books")}
              style={{
                alignSelf: "center",
                alignItems: "start",
                width: "100%",
                justifyContent: "start",
                borderRadius: "0px 0px 10px 10px",
              }}
            >
              <ListItemIcon
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <CollectionsBookmarkIcon />
                <ListItemText primary="Obras" />
              </ListItemIcon>
            </ListItemButton> */}

            <ListItemButton
              onClick={() => handleRouter("/publications")}
              style={{
                alignSelf: "center",
                alignItems: "start",
                width: "100%",
                justifyContent: "start",
                borderRadius: "10px 10px 0px 0px",
              }}
            >
              <ListItemIcon
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <LibraryBooksIcon style={{}} />
                <ListItemText primary="Obras" />
              </ListItemIcon>
            </ListItemButton>

            <ListItemButton
              onClick={() => handleRouter("/contracts")}
              style={{
                alignSelf: "center",
                alignItems: "start",
                width: "100%",
                justifyContent: "start",
                borderRadius: "10px 10px 0px 0px",
              }}
            >
              <ListItemIcon
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <AssignmentIcon style={{}} />
                <ListItemText primary="Contratos" />
              </ListItemIcon>
            </ListItemButton>

            <ListItemButton
              onClick={() => handleRouter("/metrics")}
              style={{
                alignSelf: "center",
                alignItems: "start",
                width: "100%",
                justifyContent: "start",
                borderRadius: "10px 10px 0px 0px",
              }}
            >
              <ListItemIcon
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <PaidIcon style={{}} />
                <ListItemText primary="Aceitação de compra" />
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton
              onClick={() => handleRouter("/emails-templates")}
              style={{
                alignSelf: "center",
                alignItems: "start",
                width: "100%",
                justifyContent: "start",
                borderRadius: "10px 10px 0px 0px",
              }}
            >
              <ListItemIcon
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Email style={{}} />
                <ListItemText primary="Emails" />
              </ListItemIcon>
            </ListItemButton>

            <ListItemButton
              onClick={() => handleRouter("/policies")}
              style={{
                alignSelf: "center",
                alignItems: "start",
                width: "100%",
                justifyContent: "start",
                borderRadius: "0px 0px 10px 10px",
              }}
            >
              <ListItemIcon
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <PhonelinkLockIcon />
                <ListItemText primary="Politicas e privacidade" />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
        <Typography
          alignSelf="center"
          position="fixed"
          bottom={15}
          variant="subtitle2"
          style={{ fontWeight: "bold" }}
          color="grayText"
        >
          Versão: 5.0.30
        </Typography>
      </Drawer>
      <Main open={open}>{children}</Main>
    </Box>
  );
}
