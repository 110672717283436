import React, { useEffect, useState } from "react";
import EmailTemplateSelector from "./EmailTemplateSelector";
import NetworkManager from "../Network/NetworkManager";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
const networkManager = new NetworkManager()
var utf8 = require("utf8");
var base64 = require("base-64");

const EmailTemplate = () => {
  const [selectedTemplate, setSelectedTemplate] = useState(1);
  const [mailtemplates, setmailtemplates] = useState([]);

  const [subject, setsubject] = useState("");
  const [mailbody, setmailbody] = useState("");

  useEffect(() => {
    fetchTemplates();
    setsubject(mailtemplates[selectedTemplate]?.subject);
  }, []);

  useEffect(() => {
    setsubject(mailtemplates[selectedTemplate]?.subject);
    if (mailtemplates[selectedTemplate]?.body)
      setmailbody(base64.decode(mailtemplates[selectedTemplate]?.body));
  }, [selectedTemplate,mailtemplates]);

  const fetchTemplates = async () => {
    try {
      const temp = await networkManager.getAllEmailTemplates();
      setmailtemplates(temp);
    } catch (error) {
      console.error("Erro ao buscar templates:", error);
    }
  };
  const submit = async () => {
    const body = {
      id: mailtemplates[selectedTemplate].id,
      subject: subject,
      body: base64.encode(mailbody),
    };

    const temppayload = await networkManager.setEmailTemplate(body);
    if (temppayload) console.log("SUCESS");
  };

  return (
    <div
      style={{
        padding: "20px",
        marginTop: "50px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ marginTop: 20 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>Template de Emails</h2>
          <EmailTemplateSelector
            templates={mailtemplates}
            selectedTemplate={selectedTemplate}
            onSelect={setSelectedTemplate}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <TextField
            id="outlined-basic"
            placeholder="Assunto"
            variant="outlined"
            value={subject}
            onChange={(e) => setsubject(e.target.value)}
          />
          <textarea
            name="mainBody"
            id=""
            cols="30"
            rows="10"
            value={mailbody}
            onChange={(e) => setmailbody(e.target.value)}
          ></textarea>
          <Button onClick={submit} variant="contained">
            Salvar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EmailTemplate;
