import axios from "axios";

const baseUrl = "https://sharepoint-integration-api-amd64-1-0-8.onrender.com";

export class SharePointManager {
  async getPublications() {
    const { data } = await axios.get(`${baseUrl}/api/publications`);
    return data;
  }

  async getFiles(path_name) {
    const encodedPathName = encodeURIComponent(path_name);

    const { data } = await axios.get(`${baseUrl}/api/files/${encodedPathName}`);
    return data;
  }

  async getFolders(folder_name) {
    const { data } = await axios.get(`${baseUrl}/api/folders/${folder_name}`);
    return data;
  }

  async allFilesAndAllFolders(folder_name) {
    const { data } = await axios.get(`${baseUrl}/api/all-files/${folder_name}`);
    return data;
  }

  async downloadFile(file_path) {
    const encodedPathName = encodeURIComponent(file_path);
    const { data } = await axios.get(
      `${baseUrl}/api/download/${encodedPathName}`
    );
    return data;
  }
}
