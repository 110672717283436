import React from "react";
import DrawerAppBar from "../components/DrawerAppBar";
import InsertBookForm from "../components/InsertBookForm";

const NewLiteraryWork = () => {
  return (
    <DrawerAppBar>
      <InsertBookForm />
    </DrawerAppBar>
  );
};

export default NewLiteraryWork;
