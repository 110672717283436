import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import Spinner from "react-bootstrap/Spinner";

import {
  Box,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Alert,
  MenuItem,
  Stack,
  TablePagination,
  Modal,
  Button,
  Chip,
  Switch,
  Fade,
  FormControlLabel,
} from "@mui/material";
import Row from "./Row";
import NetworkManager from "../Network/NetworkManager";
import { Divider } from "@material-ui/core";
import SearchResults from "./SearchResults";
import { useNavigate } from "react-router-dom";

const paymentMethods = ["Banck Tranfer", "ECOMMERCE_MOBILE"];
const paymentStatuses = ["ACCEPTED", "TOPAY"];

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  p: 4,
  borderRadius: "10px",
};

export default function BookSalesMetrics() {
  const [searchTerm, setSearchTerm] = useState("");
  const [purchaseIdSearchTerm, setPurchaseIdSearchTerm] = useState("");
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [bookSearchTerm, setBookSearchTerm] = useState("");
  const [rows, setRows] = useState([]);
  const [error, setError] = useState("");
  const [paymentMethodFilter, setPaymentMethodFilter] = useState("");
  const [paymentStatusFilter, setPaymentStatusFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);

  const [selectedDateSales, setSelectedDateSales] = useState(0);
  const [selectedDateAcceptedSales, setSelectedDateAcceptedSales] = useState(0);
  const [selectedDateToPaySales, setSelectedDateToPaySales] = useState(0);

  const [acceptedSales, setAcceptedSales] = useState(0);
  const [toPaySales, setToPaySales] = useState(0);
  const [salesToday, setSalesToday] = useState(0);

  const [showFreeItems, setShowFreeItems] = useState(true);

  const navigation = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigation("/");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const today = new Date().toISOString().split("T")[0];
        const networkManager = new NetworkManager();

        const purchases = await networkManager.getPurchase().catch((error) => {
          setTimeout(
            () => setError("Falha ao carregar os dados. Tente novamente."),
            2000
          );
          console.log("Error fetching data:", error);
        });

        if (Array.isArray(purchases)) {
          setRows(purchases);

          const accepted = purchases.filter(
            (purchase) => purchase.status === "ACCEPTED"
          );
          setAcceptedSales(
            accepted.reduce((acc, curr) => acc + curr.total_to_pay, 0)
          );

          const toPay = purchases.filter(
            (purchase) => purchase.status === "TOPAY"
          );
          setToPaySales(
            toPay.reduce((acc, curr) => acc + curr.total_to_pay, 0)
          );

          const todaySales = purchases.filter((purchase) =>
            purchase.payment_date.startsWith(today)
          );
          setSalesToday(
            todaySales.reduce((acc, curr) => acc + curr.total_to_pay, 0)
          );

          if (dateFilter) {
            const selectedDatePurchases = purchases.filter((purchase) =>
              purchase.payment_date.startsWith(dateFilter)
            );
            setSelectedDateSales(
              selectedDatePurchases.reduce(
                (acc, curr) => acc + curr.total_to_pay,
                0
              )
            );
            setSelectedDateAcceptedSales(
              selectedDatePurchases
                .filter((purchase) => purchase.status === "ACCEPTED")
                .reduce((acc, curr) => acc + curr.total_to_pay, 0)
            );
            setSelectedDateToPaySales(
              selectedDatePurchases
                .filter((purchase) => purchase.status === "TOPAY")
                .reduce((acc, curr) => acc + curr.total_to_pay, 0)
            );
          }
        } else {
          setRows([]);
          setError("Falha ao carregar os dados. Tente novamente.");
          logout();
        }
      } catch (err) {
        setError("Falha ao carregar os dados. Tente novamente.");
      }
    };

    fetchData();
  }, [paymentStatusFilter, dateFilter]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePurchaseIdSearchChange = (event) => {
    setPurchaseIdSearchTerm(event.target.value);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const filteredRows = rows.filter((row) => {
    const matchesFilters =
      row.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      row.purchase_id.includes(purchaseIdSearchTerm) &&
      (paymentMethodFilter
        ? row.payment_method === paymentMethodFilter
        : true) &&
      (paymentStatusFilter ? row.status === paymentStatusFilter : true) &&
      (!dateFilter || new Date(row.payment_date) >= new Date(dateFilter));
    return matchesFilters && (showFreeItems || row.price_to_pay !== "0"); // Adiciona verificação para mostrar itens não gratuitos, se aplicável
  });

  const groupedByBookId = filteredRows.reduce((acc, row) => {
    (acc[row.book_id] = acc[row.book_id] || []).push(row);
    return acc;
  }, {});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  return (
    <Box sx={{ overflowX: "auto", marginTop: "70px" }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <SearchResults />
        </Box>
      </Modal>

      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        mb={2}
        alignItems="center"
      >
        <Typography variant="h5" gutterBottom>
          Resumo
        </Typography>
        <Button
          onClick={handleOpenModal}
          sx={{ width: "fit-content", alignSelf: "start" }}
          variant="contained"
          color="primary"
        >
          Incluir livro a um usuário
        </Button>
      </Stack>

      <Stack direction="column" spacing={2} mb={2}>
        <Stack
          border="1px solid"
          borderRadius="4px"
          borderColor="grey.400"
          direction="row"
          padding={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="h6">
              <b>Hoje:</b>{" "}
              <Chip
                label={
                  salesToday === undefined || null ? (
                    <Spinner size="sm" animation="grow" />
                  ) : (
                    salesToday.toLocaleString("pt-AO", {
                      style: "currency",
                      currency: "AOA",
                    })
                  )
                }
              />
            </Typography>

            <Typography variant="h6">
              <b>Total:</b>{" "}
              <Chip
                label={
                  acceptedSales === undefined || null ? (
                    <Spinner size="sm" animation="grow" />
                  ) : (
                    acceptedSales.toLocaleString("pt-AO", {
                      style: "currency",
                      currency: "AOA",
                    })
                  )
                }
              />
            </Typography>
          </Stack>

          <Fade in={dateFilter}>
            <Stack direction="row" spacing={2} alignItems="center">
              {dateFilter && (
                <>
                  <Typography variant="p">
                    <b>Total {formatDate(dateFilter)}:</b>{" "}
                    <Chip
                      label={
                        selectedDateSales === undefined || null ? (
                          <Spinner size="sm" animation="grow" />
                        ) : (
                          selectedDateSales.toLocaleString("pt-AO", {
                            style: "currency",
                            currency: "AOA",
                          })
                        )
                      }
                    ></Chip>
                  </Typography>
                  <Typography variant="p">
                    <b>Aceites: </b>
                    <Chip
                      label={
                        selectedDateAcceptedSales === undefined || null ? (
                          <Spinner size="sm" animation="grow" />
                        ) : (
                          selectedDateAcceptedSales.toLocaleString("pt-AO", {
                            style: "currency",
                            currency: "AOA",
                          })
                        )
                      }
                      color="primary"
                    ></Chip>{" "}
                  </Typography>
                  <Typography variant="p">
                    <b>Por Pagar: </b>
                    <Chip
                      label={selectedDateToPaySales.toLocaleString("pt-AO", {
                        style: "currency",
                        currency: "AOA",
                      })}
                      color="warning"
                    ></Chip>
                  </Typography>
                </>
              )}
            </Stack>
          </Fade>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2}>
        <TextField
          label="Buscar Livro"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ mb: 2, width: "200px" }}
        />
        <TextField
          label="Buscar por Comprador"
          variant="outlined"
          fullWidth
          value={purchaseIdSearchTerm}
          onChange={handlePurchaseIdSearchChange}
          sx={{ mb: 2, width: "200px" }}
        />
        <TextField
          select
          label="Método de Pagamento"
          value={paymentMethodFilter}
          onChange={(e) => setPaymentMethodFilter(e.target.value)}
          fullWidth
          sx={{ mb: 2, width: "150px" }}
        >
          {paymentMethods.map((method) => (
            <MenuItem key={method} value={method}>
              {method}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Status do Pagamento"
          value={paymentStatusFilter}
          onChange={(e) => setPaymentStatusFilter(e.target.value)}
          fullWidth
          sx={{ mb: 2, width: "150px" }}
        >
          {paymentStatuses.map((status) => (
            <MenuItem key={status} value={status}>
              {status === "ACCEPTED" ? "Aceito" : "Para Pagar"}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Data"
          type="date"
          variant="outlined"
          placeholder="Insira a data"
          value={dateFilter}
          onChange={(e) => setDateFilter(e.target.value)}
          fullWidth
          sx={{ mb: 2, width: "250px" }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControlLabel
          control={
            <Switch
              checked={showFreeItems}
              onChange={(e) => setShowFreeItems(e.target.checked)}
              name="showFreeItems"
            />
          }
          label="Mostrar itens gratuitos"
        />
      </Stack>
      <Fade in={groupedByBookId}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Capa</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Preço</TableCell>
                <TableCell>Compras aceitas</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(groupedByBookId)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((group, index) => (
                  <Row key={index} group={group} />
                ))}
              {Object.values(groupedByBookId).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} style={{ textAlign: "center" }}>
                    <Typography variant="h6">
                      Nenhum resultado encontrado
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={Object.values(groupedByBookId).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Fade>
    </Box>
  );
}
