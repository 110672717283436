import React, { useState, useEffect } from "react";
import {
  Button,
  List,
  ListItem,
  Typography,
  useTheme,
  Card,
  CardContent,
} from "@mui/material";

const subscribersData = [
  {
    name: "João Silva",
    email: "joao.silva@example.com",
    dateSubscribed: "2021-01-01",
  },
  {
    name: "Maria Fernandes",
    email: "maria.fernandes@example.com",
    dateSubscribed: "2021-02-15",
  },
];

export function SubscribersList() {
  const [subscribers, setSubscribers] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    setSubscribers(subscribersData);
  }, []);

  return (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        marginTop: theme.spacing(7),
      }}
    >
      <Button>Novo Subscritor</Button>
      {subscribers.map((subscriber, index) => (
        <ListItem
          key={index}
          style={{ padding: theme.spacing(1) }}
          component="div"
          disablePadding
        >
          <Card
            variant="outlined"
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              padding: theme.spacing(1),
            }}
          >
            <CardContent>
              <Typography variant="h6" component="div">
                {subscriber.name}
              </Typography>
              <Typography variant="body2">Email: {subscriber.email}</Typography>
              <Typography variant="body2">
                Data de Subscrição: {subscriber.dateSubscribed}
              </Typography>
            </CardContent>
            <Button>Detalhes</Button>
          </Card>
        </ListItem>
      ))}
    </List>
  );
}
