import React from "react";
import DrawerAppBar from "../components/DrawerAppBar";
import { PublicationsList } from "../components/PublicationsList";

const Publications = () => {
  return (
    <DrawerAppBar>
      <PublicationsList />
    </DrawerAppBar>
  );
};

export default Publications;
