import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Stack,
  CardMedia,
  Button,
} from "@mui/material";
import DrawerAppBar from "../components/DrawerAppBar";
import { DeleteForeverOutlined, EditNote } from "@mui/icons-material";
import book1 from "../assets/books/book1.png";
import book2 from "../assets/books/book2.webp";
import book3 from "../assets/books/book3.webp";
import book4 from "../assets/books/book4.webp";
import BookEditModal from "../components/BookEditModal";
import { Divider } from "@material-ui/core";

const booksData = [
  {
    id: 1,
    title: "Book Title 1",
    author: "Author 1",
    description: "Description 1",
    imageUrl: book1,
  },
  {
    id: 2,
    title: "Book Title 2",
    author: "Author 2",
    description: "Description 2",
    imageUrl: book2,
  },
  {
    id: 3,
    title: "Book Title 3",
    author: "Author 3",
    description: "Description 3",
    imageUrl: book3,
  },
  {
    id: 4,
    title: "Book Title 4",
    author: "Author 4",
    description: "Description 4",
    imageUrl: book4,
  },
];

const SupplierDetailsPage = ({ supplier }) => {
  const [books, setBooks] = useState(booksData); // Use state to manage books
  const [selectedBook, setSelectedBook] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEditBook = (book) => {
    setSelectedBook(book);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveEditedBook = (editedBook) => {
    const updatedBooks = books.map((book) =>
      book.id === editedBook.id ? editedBook : book
    );
    setBooks(updatedBooks);
    handleCloseModal();
  };

  const supplierDetails = supplier || {
    name: "Alpha Books",
    description: "Specializes in academic materials.",
    contract: "Contracto: 000/24",
  };

  return (
    <DrawerAppBar>
      <Stack spacing={2} sx={{ marginTop: 10, padding: 2 }}>
        <Typography variant="h4">{supplierDetails.name}</Typography>
        <Typography>{supplierDetails.description}</Typography>
        <Typography>{supplierDetails.contract}</Typography>
      </Stack>
      <Typography variant="h5" sx={{ marginTop: 2, padding: 2 }}>
        Livros
      </Typography>
      <Divider
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          color: "#000000",
          backgroundColor: "#000000",
          width: "97%",
          height: 2,
          marginTop: 2,
          marginBottom: 2,
        }}
      />
      <Grid container spacing={2} p="20px">
        {books.map((book) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={book.id}>
            <Card variant="outlined" sx={{ height: "100%" }}>
              <CardMedia
                component="img"
                height="200"
                image={book.imageUrl}
                alt={book.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {book.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {book.author}
                </Typography>
              </CardContent>

              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="start"
                padding={1}
              >
                <Button
                  endIcon={<EditNote />}
                  size="small"
                  onClick={() => handleEditBook(book)}
                >
                  Editar
                </Button>
                <Button
                  endIcon={<DeleteForeverOutlined />}
                  style={{ color: "red" }}
                  size="small"
                >
                  Remover
                </Button>
              </Stack>
            </Card>
          </Grid>
        ))}
      </Grid>
      {selectedBook && (
        <BookEditModal
          open={isModalOpen}
          onClose={handleCloseModal}
          bookDetails={selectedBook}
          onSave={handleSaveEditedBook}
        />
      )}
    </DrawerAppBar>
  );
};

export default SupplierDetailsPage;
