import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useNavigate } from "react-router-dom";
import { Add, HorizontalRule } from "@mui/icons-material";
import { Typography } from "@mui/material";

export function UserMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [version, setVersion] = React.useState("5.0.30");

  const HandleLogOut = async () => {
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const incrementVersion = () => {
    const versionParts = version.split(".").map(Number);
    versionParts[2] += 1;
    setVersion(versionParts.join("."));
  };

  const decrementVersion = () => {
    const versionParts = version.split(".").map(Number);
    if (versionParts[2] > 0) {
      versionParts[2] -= 1;
      setVersion(versionParts.join("."));
    }
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <ManageAccountsIcon color="black" />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem>
          <Button
            onClick={incrementVersion}
            size="small"
            style={{
              width: "auto",
              height: "auto",
              padding: "0",
            }}
          >
            <Add />
          </Button>
          <Typography
            color="grayText"
            variant="subtitle2"
            style={{ fontWeight: "bold" }}
          >
            Versão do cache {version}
          </Typography>
          <Button
            onClick={decrementVersion}
            size="small"
            style={{
              width: "auto",
              height: "auto",
              padding: "0",
            }}
          >
            <HorizontalRule />
          </Button>
        </MenuItem>

        <MenuItem
          onClick={HandleLogOut}
          style={{
            color: "red ",
          }}
        >
          Sair
        </MenuItem>
      </Menu>
    </div>
  );
}
