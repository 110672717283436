import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  CircularProgress,
  Paper,
  Avatar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import logo from "../assets/kioxke-logo.png";
import library from "../assets/library.jpg";
import NetworkManager from "../Network/NetworkManager";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const navigate = useNavigate();
  const { addToast } = useToasts();

  const networkManager = new NetworkManager();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await networkManager
        .postLogin(email, password)
        .then((data) => {
          console.log(data);
          if (data.token && !data.error) {
            navigate("/home");
            addToast("Login realizado com sucesso", {
              appearance: "success",
              autoDismiss: true,
            });

            localStorage.setItem("user_data", email);
            window.location.reload();
          } else {
            addToast("Credenciais inválidas, tente novamente", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });

      await new Promise((resolve) => setTimeout(resolve, 2000));
    } catch (error) {
      console.error("Erro no login:", error);
      addToast("Credenciais inválidas, tente novamente", {
        appearance: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 10 }}>
      <img
        style={{
          zIndex: -1,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        height={100}
        width={100}
        src={library}
      />
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <img src={logo} alt="logo kioxke" width={40} height={40} />
        </Avatar>
        <Typography component="h1" variant="h5">
          Control Tower Login
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, backgroundColor: "#4285F4", color: "white" }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Entrar"}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default Login;
