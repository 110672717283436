import React from "react";
import DrawerAppBar from "../components/DrawerAppBar";
import EmailTemplate from "../components/EmailTemplate";

const Emails = () => {
  return (
    <DrawerAppBar>
      <EmailTemplate />
    </DrawerAppBar>
  );
};

export default Emails;
