import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SalesCard from "../views/Card";
import { useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import {
  Button,
  TextField,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridOnIcon from "@mui/icons-material/GridOn";
import { Stack } from "@mui/material";
import NetworkManager from "../Network/NetworkManager";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const SingleOrg = () => {
  const [salesData, setSalesData] = useState([]);
  const [allSalesData, setAllSalesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewType, setViewType] = useState("card"); // State para controlar o tipo de visualização

  const { id } = useParams();
  const environment = localStorage.getItem("env");

  const [startDate, setStartDate] = useState(dayjs().startOf("year"));
  const [endDate, setEndDate] = useState(dayjs());

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  const networkManager = new NetworkManager();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const fetchedData = await networkManager.fetchSalesReport(
        id,
        environment
      );
      setAllSalesData(fetchedData);
      setSalesData(fetchedData);
    } catch (error) {
      console.error("Erro ao buscar dados de vendas: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const applyFilter = async () => {
    setIsLoading(true);
    try {
      const formattedStartDate = startDate.format("YYYY-MM-DDTHH:mm:ss");
      const formattedEndDate = endDate.format("YYYY-MM-DDTHH:mm:ss");

      const filteredSalesData = await networkManager.fetchSalesFiltered(
        id,
        environment,
        {
          startdate: formattedStartDate,
          enddate: formattedEndDate,
        }
      );
      setSalesData(filteredSalesData);
    } catch (error) {
      console.error("Erro enquanto filtra os dados: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetFilter = () => {
    setSalesData(allSalesData);
    setStartDate(dayjs().startOf("year"));
    setEndDate(dayjs());
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  return (
    <Stack
      style={{
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        padding: "70px",
      }}
    >
      <Stack elevation={3} padding="20px" margin="20px" gap={2} width="100%">
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5" gutterBottom>
            Detalhes da organização {salesData[0]?.Fornecedor}
          </Typography>

          <Stack direction="row" spacing={2}>
            <Button
              onClick={() => setViewType("card")}
              variant={viewType === "card" ? "outlined" : "text"}
              color="primary"
            >
              <GridOnIcon />
            </Button>
            <Button
              onClick={() => setViewType("table")}
              variant={viewType === "table" ? "outlined" : "text"}
              color="primary"
            >
              <FormatListBulletedIcon />
            </Button>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Stack>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <DatePicker
                  label="Data Inicial"
                  value={startDate}
                  onChange={(newDate) => setStartDate(dayjs(newDate))}
                  renderInput={(params) => <TextField {...params} />}
                  format="DD/MM/YYYY"
                />
                <Typography variant="body2">{" - "}</Typography>
                <DatePicker
                  label="Data Final"
                  value={endDate}
                  onChange={(newDate) => setEndDate(dayjs(newDate))}
                  renderInput={(params) => <TextField {...params} />}
                  format="DD/MM/YYYY"
                />

                <Stack direction="row">
                  <Button
                    onClick={applyFilter}
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: "10px", height: "55px" }}
                  >
                    Aplicar
                  </Button>
                  <Button
                    onClick={resetFilter}
                    variant="outlined"
                    color="secondary"
                    style={{ marginLeft: "10px", height: "55px" }}
                  >
                    Resetar
                  </Button>
                </Stack>
              </Stack>
            </LocalizationProvider>
          </Stack>
        </Stack>
      </Stack>
      <Stack alignSelf="center" alignItems="center" paddingX={3}>
        {isLoading ? (
          <Stack className="text-center">
            <Spinner animation="grow" />
          </Stack>
        ) : viewType === "card" ? (
          <Row xs={1} md={2} lg={3} className="mb-3 align-items-center">
            {salesData.map((sale) => (
              <Col key={sale.id}>
                <SalesCard {...sale} />
              </Col>
            ))}
          </Row>
        ) : (
          <div className="table-responsive" style={{ width: "100%" }}>
            <Table className="table table-striped table-md">
              <TableHead>
                <TableRow>
                  <TableCell>Título</TableCell>
                  <TableCell>Referência</TableCell>
                  <TableCell>Forma de pagamento</TableCell>
                  <TableCell>Data de Compra</TableCell>
                  <TableCell>Preço total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {salesData.map((sale) => (
                  <TableRow key={sale.id}>
                    <TableCell>{sale.titulo}</TableCell>
                    <TableCell>{sale.referencia}</TableCell>
                    <TableCell>{sale.forma_pagamento}</TableCell>
                    <TableCell>{formatDate(sale.databuy)}</TableCell>
                    <TableCell>{sale.TotalPrice + ".00 Kz"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </Stack>
    </Stack>
  );
};

export default SingleOrg;
