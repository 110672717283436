import React from "react";
import DrawerAppBar from "../components/DrawerAppBar";
import { SuppliersAndAuthorsList } from "../components/SuppliersList";

function Suppliers() {
  return (
    <DrawerAppBar>
      <SuppliersAndAuthorsList />
    </DrawerAppBar>
  );
}

export default Suppliers;
