import React from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Chip,
  Avatar,
  CardHeader,
  Badge,
  Tooltip,
  CardActions,
} from "@material-ui/core";
import ShareIcon from "@mui/icons-material/Share";
import BookIcon from "@mui/icons-material/Book";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";

import { LazyLoadImage } from "react-lazy-load-image-component";
// Importe os estilos padrões da biblioteca para efeitos de carregamento
import "react-lazy-load-image-component/src/effects/blur.css";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const SalesCard = ({
  id,
  databuy,
  Fornecedor,
  tipo,
  capa,
  edicao,
  titulo,
  preco_pagar,
  forma_pagamento,
  Pagamento,
  TotalPrice,
  Royalties,
  referencia,
  currentPage,
  filtreTime,
  Status,
  NumDuplicates,
}) => {
  return (
    <Card sx={{ maxWidth: 345 }} raised className="mb-3 align-self-center">
      <CardHeader
        avatar={
          <LazyLoadImage
            aria-label="sale"
            effect="blur"
            width={80}
            height={100}
            style={{ objectFit: "cover" }}
            src={capa}
          />
        }
        action={
          <Tooltip title="Compartilhar">
            <IconButton aria-label="share">
              <ShareIcon />
            </IconButton>
          </Tooltip>
        }
        title={titulo}
        subheader={`Edição: ${edicao}`}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="div">
          Fornecedor: {Fornecedor}
          <br />
          Tipo: {tipo}
          <br />
          Preço a Pagar: <b>{preco_pagar} Kz</b>
          <br />
          Forma de Pagamento: {forma_pagamento}
          <br />
          Pagamento: {Pagamento}
          <br />
          Preço Total: {TotalPrice} Kz
          <br />
          Royalties: {Royalties} Kz
          <br />
          Referência: {referencia}
          <br />
          Número de Duplicatas: {NumDuplicates}
          <br />
          Data de Compra: <DateRangeIcon fontSize="small" />{" "}
          {dayjs(databuy).format("DD/MM/YYYY")}
          <br />
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Tooltip title="Total de páginas visualizadas">
          <IconButton>
            <CopyAllIcon /> {currentPage}
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

export default SalesCard;
