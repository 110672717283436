import React, { useState, useEffect } from "react";
import {
  Button,
  List,
  ListItem,
  Typography,
  useTheme,
  Card,
  CardContent,
} from "@mui/material";

const salesData = [
  {
    productTitle: "Livro ABC",
    quantity: 10,
    value: "$100.00",
  },
  {
    productTitle: "Livro XYZ",
    quantity: 5,
    value: "$50.00",
  },
];

export function SalesList() {
  const [sales, setSales] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    setSales(salesData);
  }, []);

  return (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        marginTop: theme.spacing(4),
      }}
    >
      <Button>Novo Registro de Venda</Button>
      {sales.map((sale, index) => (
        <ListItem
          key={index}
          style={{ padding: theme.spacing(1) }}
          component="div"
          disablePadding
        >
          <Card
            variant="outlined"
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              padding: theme.spacing(1),
            }}
          >
            <CardContent>
              <Typography variant="h6" component="div">
                {sale.productTitle}
              </Typography>
              <Typography variant="body2">
                Quantidade: {sale.quantity}
              </Typography>
              <Typography variant="body2">Valor: {sale.value}</Typography>
            </CardContent>
            <Button>Detalhes</Button>
          </Card>
        </ListItem>
      ))}
    </List>
  );
}
