import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Popover,
  Stack,
  Divider,
} from "@mui/material";
import { SearchSharp } from "@mui/icons-material";
import NetworkManager from "../Network/NetworkManager";

import avatarImage from "../assets/avatar/men.jpg";

function SearchResults({ results, onSelect }) {
  return (
    <Box>
      {results.length > 0 ? (
        results.map((result, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              padding: 2,
              cursor: "pointer",
              "&:hover": { backgroundColor: "#f0f0f0" },
            }}
            onClick={() => onSelect(result)}
          >
            <Box>
              <Typography variant="subtitle1">
                {result.titulo || result.firstname}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {result.escritor || result.email}
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                backgroundColor: "orange",
              }}
            />
          </Box>
        ))
      ) : (
        <Typography variant="body1">Nenhum resultado encontrado.</Typography>
      )}
    </Box>
  );
}

function SearchComponent() {
  const [bookSearchTerm, setBookSearchTerm] = useState("");
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const bookSearchButtonRef = useRef(null);
  const userSearchButtonRef = useRef(null);
  const [selectedResult, setSelectedResult] = useState(null);
  const [users, setUsers] = useState([]);
  const [books, setBooks] = useState([]);
  const networkManager = new NetworkManager();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersData = await networkManager.fetchAllUsers();
        setUsers(usersData);

        const booksData = await networkManager.getBooksByViews();
        setBooks(booksData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleBookSearch = () => {
    const filteredBooks = books.filter((book) =>
      book.titulo.toLowerCase().includes(bookSearchTerm.toLowerCase())
    );
    setSearchResults(filteredBooks);
    setAnchorEl(bookSearchButtonRef.current);
  };

  const handleUserSearch = () => {
    const filteredUsers = users.filter((user) =>
      user.firstname.toLowerCase().includes(userSearchTerm.toLowerCase())
    );
    setSearchResults(filteredUsers);
    setAnchorEl(userSearchButtonRef.current);
  };

  const handleClose = (result) => {
    setAnchorEl(null);
    setSelectedResult(result);
  };

  const data = { users, books };

  const handleSubmit = async () => {
    try {
      networkManager.addBookToUser(data);
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" mb={2}>
        Buscar Livros
      </Typography>
      <TextField
        fullWidth
        label="Buscar Livro"
        variant="outlined"
        value={bookSearchTerm}
        onChange={(e) => setBookSearchTerm(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Stack direction="row" spacing={2} alignItems="center" mb={2}>
        <Button
          variant="outlined"
          startIcon={<SearchSharp />}
          ref={bookSearchButtonRef}
          onClick={handleBookSearch}
        >
          Buscar Livros
        </Button>
        <Typography>Livro Selecionado: {selectedResult?.titulo}</Typography>
      </Stack>

      <Typography variant="h6" mb={2}>
        Buscar Usuários
      </Typography>
      <TextField
        fullWidth
        label="Buscar Usuário"
        variant="outlined"
        value={userSearchTerm}
        onChange={(e) => setUserSearchTerm(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Stack direction="row" spacing={2} alignItems="center" mb={2}>
        <Button
          variant="outlined"
          startIcon={<SearchSharp />}
          ref={userSearchButtonRef}
          onClick={handleUserSearch}
        >
          Buscar Usuários
        </Button>
        <Typography>
          Usuário Selecionado: {selectedResult?.firstname}
        </Typography>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Button variant="contained" onClick={() => handleSubmit()}>
          Incluir Livro
        </Button>
      </Stack>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2 }}>
          <SearchResults results={searchResults} onSelect={handleClose} />
        </Box>
      </Popover>
    </Box>
  );
}

export default SearchComponent;
