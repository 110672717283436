import React from "react";
import DrawerAppBar from "../components/DrawerAppBar";
import { SalesList } from "../components/SalesList";

const Sales = () => {
  return (
    <DrawerAppBar>
      <h1>Sales</h1>

      <SalesList />
    </DrawerAppBar>
  );
};

export default Sales;
