import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Table,
  TableHead,
  TableBody,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { LazyLoadImage } from "react-lazy-load-image-component";
// Importe os estilos padrões da biblioteca para efeitos de carregamento
import "react-lazy-load-image-component/src/effects/blur.css";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import NetworkManager from "../Network/NetworkManager";

function Row({ group }) {
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedBookId, setSelectedBookId] = useState(null);
  const [status, setStatus] = useState(false);

  const handleOpenDialog = (bookId) => {
    setSelectedBookId(bookId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleAcceptPurchase = async () => {
    const networkManager = new NetworkManager();
    try {
      await networkManager.setPurchase({ ref: selectedBookId });
      setStatus(true);
      handleCloseDialog();
    } catch (error) {
      console.error("Error accepting purchase:", error);
    }
  };

  const acceptedPurchases = group.filter(
    (book) => book.status === "ACCEPTED"
  ).length;
  const totalPurchases = group.length;
  const purchasesSummary = `${acceptedPurchases} / ${totalPurchases} compras aceites`;

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <LazyLoadImage
            src={group[0].cover}
            alt="Book Cover"
            effect="blur"
            style={{ objectFit: "cover" }}
            width={30}
            height={40}
          />
        </TableCell>
        <TableCell>{group[0].title}</TableCell>

        <TableCell
          style={{
            fontWeight: "bold",
          }}
        >
          {group[0].price_to_pay === "0"
            ? "Gratuito"
            : `${(group[0].price_to_pay * totalPurchases).toLocaleString(
                "pt-AO",
                { style: "currency", currency: "AOA" }
              )}`}
        </TableCell>

        <TableCell>{purchasesSummary}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Titulo</TableCell>
                    <TableCell>Email comprador</TableCell>
                    <TableCell>Referência</TableCell>
                    <TableCell>Preço total</TableCell>
                    <TableCell align="right">Aceitar compra</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {group.map((book, index) => (
                    <TableRow key={index}>
                      <TableCell>{book.title}</TableCell>
                      <TableCell>{book.purchase_id}</TableCell>
                      <TableCell
                        style={{ fontWeight: "bold" }}
                      >{`${book.reference}`}</TableCell>

                      <TableCell style={{ fontWeight: "bold" }}>
                        {`${book.total_to_pay
                          .toLocaleString("pt-AO", {
                            style: "currency",
                            currency: "AOA",
                          })
                          .replace(/\.00$/, "")}`}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          onClick={() => handleOpenDialog(book.reference)}
                          size="small"
                          disabled={
                            book.status === "ACCEPTED" || status === true
                          }
                        >
                          {book.status === "ACCEPTED" ? "Aceite" : "Aceitar"}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{"Confirmação de compra"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Você quer aceitar esta compra?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button onClick={handleAcceptPurchase} autoFocus>
            Aceitar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Row;
