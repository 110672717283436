import React from "react";
import DrawerAppBar from "../components/DrawerAppBar";
import { ContractsList } from "../components/ContractsList";

const Contracts = () => {
  return (
    <DrawerAppBar>
      <ContractsList />
    </DrawerAppBar>
  );
};

export default Contracts;
