import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

const EmailTemplateSelector = (props) => {
  const { templates, onSelect, selectedTemplate } = props;

  const [open, setOpen] = useState(false);
  const [selected, setselected] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTemplateChange = (event) => {
    onSelect(selected);
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleOpen}>
        Select Email Template
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select an Email Template</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="template-select-label">Template</InputLabel>
            <Select
              labelId="template-select-label"
              id="template-select"
              value={selected}
              onChange={(e) => setselected(e.target.value)}
              fullWidth
            >
              {/* {templates[0]?.subject} */}
              {/* <MenuItem key={0} value={""}>{templates[0].subject}</MenuItem> */}
              {templates.map((template, index) => (
                <MenuItem key={index} value={index}>
                  {template.subject}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleTemplateChange()}
            variant="contained"
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EmailTemplateSelector;
