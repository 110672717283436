import axios from "axios";
import Cookies from "js-cookie";

class NetworkManager {
  constructor() {
    this.API_BASE_URL = "https://api.kioxke.ao/v0.4/";
    this.API_BASE_ADMIN_URL = "https://admin.kioxke.ao/api/v0.1/";
  }

  async handleRequest(
    method,
    endpoint,
    data = {},
    config = {},
    isAdmin = false,
    env
  ) {
    const authToken = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("RefreshToken");

    const defaultHeaders = {
      Authorization: `Bearer ${authToken}`,
      RefreshToken: `Bearer ${refreshToken}`,
      SEGMENTATION: env, // Enviroment network (TEST or Production)
      "Content-Type":
        data instanceof FormData ? "multipart/form-data;" : "application/json;",
    };

    const headers = { ...defaultHeaders, ...config.headers };
    const requestData =
      method.toLowerCase() === "get" ? { params: data } : { data: data };

    try {
      const response = await axios({
        method,
        maxBodyLength: Infinity,
        url: `${
          isAdmin ? this.API_BASE_ADMIN_URL : this.API_BASE_URL
        }${endpoint}`,
        ...requestData,
        headers,
        ...config,
      });

      return response.data;
    } catch (error) {
      console.log(`Error making ${method} request to ${endpoint}:`, error);
      console.error(`Error making ${method} request to ${endpoint}:`, error);
      throw error;
    }
  }

  async insertSingleBook(data, config = {}) {
    return this.handleRequest("post", "book/upload", data, config, true);
  }

  async getRequest(params, config = {}) {
    return this.handleRequest("get", "", { params, ...config });
  }

  async getAllNumContracts(params, config = {}) {
    return this.handleRequest(
      "get",
      "book/getAllcontractsNum?db=produc",
      params,
      config,
      true
    );
  }

  async getBooksByViews() {
    const authToken = localStorage.getItem("token");

    try {
      const response = await axios.get(
        `${this.API_BASE_URL}getbooks?by=views`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            RefreshToken: `Bearer ${localStorage.getItem("RefreshToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error getting books by views:", error);
      throw error;
    }
  }

  async getEmailTemplate(index) {
    return axios.get(`${this.API_BASE_URL}emails/templates/${index}`);
  }

  async getAllEmailTemplates() {
    return this.handleRequest("get", "emails/templates/all", {}, {});
  }

  async setEmailTemplate(data) {
    return this.handleRequest("post", "emails/template/set", data, {});
  }

  async deleteBook(id) {
    return this.handleRequest("delete", `book/delete/${id}`);
  }

  async getPurchase() {
    return this.handleRequest("get", "book/getPurchase");
  }

  async setPurchase(data, config = {}) {
    return this.handleRequest("post", "register/setPurchase", data, config);
  }

  async fetchOrganizations(environment) {
    try {
      return this.handleRequest(
        "get",
        `organizations?db=${environment}`,
        {},
        {},
        true
      );
    } catch (error) {
      console.error("Error fetching organizations:", error);
      throw error;
    }
  }

  async sendEmail(email, organizationId, environment) {
    return this.handleRequest(
      "get",
      `salesReportEmail?p=${email}&organizationId=${organizationId}&db=${environment}`,
      {},
      {},
      true
    );
  }

  async getEmailBody(email, organizationId, environment) {
    return this.handleRequest(
      "post",
      `email/preview/${organizationId}`,
      {},
      {},
      true
    );
  }

  async fetchSalesReport(id, environment, data = {}) {
    try {
      return this.handleRequest(
        "post",
        `salesReport?id=${id}&db=${environment}`,
        data,
        {},
        true
      );
    } catch (error) {
      console.error("Error fetching sales report:", error);
      throw error;
    }
  }

  async fetchSalesFiltered(id, environment, data = {}) {
    try {
      return this.handleRequest(
        "post",
        `salesReport?id=${id}&db=${environment}`,
        data,
        {},
        true
      );
    } catch (error) {
      console.error("Error fetching sales report:", error);
      throw error;
    }
  }

  async fetchAllUsers() {
    try {
      return this.handleRequest("get", `users/getall`, {}, {}, true);
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  }

  async postLogin(email, password) {
    try {
      const { data, status } = await axios.post(`${this.API_BASE_URL}login`, {
        use_email: email,
        use_senha: password,
      });
      if (status !== 200) {
        throw new Error("Error on login");
      }
      localStorage.setItem("token", data.token);
      localStorage.setItem("RefreshToken", data.refreshToken);

      Cookies.set("AUTH", password, { expires: 2 });

      return data;
    } catch (error) {
      console.error("Error on login:", error);
      throw error;
    }
  }

  async fetchOrganizationSales(environment) {
    try {
      return this.handleRequest(
        "get",
        `users/organizations?db=${environment}`,
        true
      );
    } catch (error) {
      console.error("Error fetching organization sales:", error);
      throw error;
    }
  }

  async addDiscount(data, environment) {
    try {
      return this.handleRequest(
        "post",
        `book/set?db=${environment}`,
        data,
        {},
        true
      );
    } catch (error) {
      console.error("Error adding discount:", error);
      throw error;
    }
  }

  async addBookToUser(data, environment) {
    try {
      return this.handleRequest(
        "post",
        "book/set?db=" + environment,
        data,
        {},
        true
      );
    } catch (error) {
      console.error("Error adding book to user:", error);
      throw error;
    }
  }

  async editBook(data, environment) {
    try {
      return this.handleRequest(
        "post",
        `book/edit?db=${environment}`,
        data,
        {},
        true
      );
    } catch (error) {
      console.error("Error editing book:", error);
      throw error;
    }
  }

  async InsertBookForm(data) {
    try {
      return this.handleRequest(
        "post",
        "book/insertBookSingle",
        { ...data, size: "7.30 MB" },
        {},
        true
      );
    } catch (error) {
      console.error("Error adding book to user:", error);
      throw error;
    }
  }

  async activateBook(id) {
    try {
      return this.handleRequest(
        "post",
        "book/split/update/" + id,
        {},
        {},
        true
      );
    } catch (error) {
      console.error("Error adding book to user:", error);
      throw error;
    }
  }
}
export default NetworkManager;
