// src/components/BookEditModal.js
import React from "react";
import { useForm } from "react-hook-form";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Stack,
} from "@mui/material";
import EditBookForm from "./EditBookForm";

const BookEditModal = ({ open, onClose, bookDetails, onSave }) => {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: bookDetails, // Populate form with selected book's details
  });

  React.useEffect(() => {
    if (bookDetails) {
      reset(bookDetails); // Reset form with current book details when bookDetails change
    }
  }, [bookDetails, reset]);

  const onSubmit = (data) => {
    onSave(data);
    onClose();
  };

  console.log(bookDetails);

  return (
    <Stack padding={10}>
      <Dialog open={open} onClose={onClose} scroll="paper" fullWidth>
        <DialogTitle>Editar Livro</DialogTitle>
        <DialogContent>
          <EditBookForm data={bookDetails} />
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default BookEditModal;
