import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dropzone from "react-dropzone";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Grid from "@mui/material/Grid";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Stack,
} from "@mui/material";
import { Spinner } from "react-bootstrap";
import NetworkManager from "../Network/NetworkManager";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const networkManager = new NetworkManager();

const EditBookForm = (bookDetails) => {
  console.log(bookDetails);

  const { data } = bookDetails;
  const [formData, setFormData] = useState({
    id: data ? data.id : "",
    titulo: data ? data.titulo : "",
    preco: data ? data.preco : 0,
    descricao: data ? data.descricao : "",
    autor: data ? data.autor : "",
    categoria: data ? data.categoria : "",
    subcate: data ? data.subcate : "",
    capa: data ? data.capa : "",
    desenhista: data ? data.desenhista : "",
    escritor: data ? data.escritor : "",
    ISBN: data ? data.ISBN : "",
    edicao: data ? data.edicao : "",
    periodicidade: data ? data.periodicidade : "",
    numContrato: data ? data.numContrato : "010/21",
    Royalties: data ? data.Royalties : "",
    subsid: data ? data.subsid : "",
    srcList: data ? data.srcList : "",
    totalpage: data ? data.totalpage : "",
    size: data ? data.size : "0BK",
  });

  const [srcList, setsrcList] = useState([]);
  const [numContratos, setNumContratos] = useState([]);
  const [categoriaBook, setCategoriaBook] = useState([
    "Livros",
    "Revista",
    "Bd",
    "Jornais",
  ]);
  const [pdfOrEpubFile, setPdfOrEpubFile] = useState(null);
  const [jpgFiles, setJpgFiles] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadProgressImage, setUploadProgressImage] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getallNumContratos();
  }, []);

  useEffect(() => {
    if (pdfOrEpubFile != null) {
      const fileSize = (pdfOrEpubFile.size / (1024 * 1024)).toFixed(2);
      setFormData({ ...formData, srcList: srcList, size: fileSize + "MB" });
    }
  }, [srcList]);

  const getallNumContratos = async () => {
    const result = await networkManager.getAllNumContracts({});
    if (result) {
      setNumContratos(result);
    } else {
      toast.success("Erro ao buscar contratos!");
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    await networkManager
      .InsertBookForm(formData)
      .then(() => {
        toast.success("Livro editado com sucesso!");
      })
      .catch(() => {
        toast.error("Erro ao editar o livro!");
      });

    setLoading(false);
  };

  const handlePdfOrEpubDrop = async (acceptedFiles) => {
    if (formData.titulo == "" || formData.totalpage == "") {
      return;
    }

    const file = acceptedFiles[0];

    setPdfOrEpubFile(file);
    setUploadProgress(0);

    try {
      const formDataInput = new FormData();
      formDataInput.append("file", file);
      formDataInput.append("title", formData.titulo);
      formDataInput.append("totalpage", formData.totalpage);
      setLoading(true);
      const response = await networkManager.insertSingleBook(formDataInput, {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setUploadProgress(progress);
        },
      });
      setsrcList(JSON.stringify(response.file_path));
      setLoading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleJpgDrop = async (acceptedFiles) => {
    if (formData.titulo == "") {
      return;
    }

    const file = acceptedFiles[0];

    setJpgFiles(file);

    try {
      const formDataInput = new FormData();
      formDataInput.append("file", file);
      formDataInput.append("title", formData.titulo);

      const response = await networkManager.insertSingleBook(formDataInput, {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setUploadProgressImage(progress);
        },
      });

      setFormData({ ...formData, capa: response.file_path });
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const handleRoyaltiesChange = (event, value) => {
    setFormData({ ...formData, Royalties: value });
  };

  return (
    <Stack style={{ padding: "10px" }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              style={{
                color: "GrayText",
              }}
              label="Titulo"
              name="titulo"
              value={formData.titulo}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              style={{
                color: "GrayText",
              }}
              label="Preco"
              name="preco"
              value={formData.preco}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="categoria-label">
                Selecionado {formData.categoria}
              </InputLabel>
              <Select
                labelId="categoria-label"
                label={formData.categoria}
                id="categoria-select"
                value={formData.categoria}
                name="categoria"
                onChange={handleInputChange}
              >
                {categoriaBook.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              style={{
                color: "GrayText",
              }}
              label="Author"
              name="autor"
              value={formData.autor}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              style={{
                color: "GrayText",
              }}
              label="Total de paginas"
              name="totalpage"
              type="number"
              value={formData.srcList.totalPage}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              style={{
                color: "GrayText",
              }}
              label="Subcategory"
              name="subcate"
              value={formData.subcate}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />

            {formData.categoria === "Revistas" ||
            formData.categoria === "BD" ? (
              <TextField
                style={{
                  color: "GrayText",
                }}
                label="Artista"
                name="desenhista"
                value={formData.desenhista}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            ) : null}

            {formData.categoria === "Livros" ? (
              <TextField
                style={{
                  color: "GrayText",
                }}
                label="ISBN"
                name="ISBN"
                value={formData.ISBN}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            ) : null}

            <TextField
              style={{
                color: "GrayText",
              }}
              label="Edicão"
              name="edicao"
              value={formData.edicao}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              style={{
                color: "GrayText",
              }}
              placeholder="Periodicity"
              name="periodicidade"
              value={formData.periodicidade}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />

            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel
                  style={{ marginTop: "30px" }}
                  id="demo-simple-select-label"
                >
                  Contrato
                </InputLabel>
                <Select
                  style={{ marginTop: "15px" }}
                  value={formData.numContrato}
                  name="numContrato"
                  onChange={handleInputChange}
                >
                  {Array.isArray(numContratos) &&
                    numContratos.map((item, index) => (
                      <MenuItem key={index} value={item.num}>
                        <strong>{item.num}</strong> - {item.nome}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <div style={{ padding: 10, width: "100%" }}>
            <Typography gutterBottom>
              Royalties: {formData.Royalties}%
            </Typography>
            <Slider
              value={formData.Royalties}
              onChange={handleRoyaltiesChange}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}%`}
              min={0}
              max={100}
            />
            <TextField
              style={{
                color: "GrayText",
              }}
              label="Descricão da Obra *Max 2000 "
              name="descricao"
              value={formData.descricao}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              multiline
              rows={5}
            />
          </div>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Dropzone
                onDrop={handlePdfOrEpubDrop}
                accept=".pdf, .epub"
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    <CloudUploadIcon fontSize="large" />
                    <small>Drop a PDF or EPUB file here</small>
                  </div>
                )}
              </Dropzone>
            </Grid>

            <Grid item xs={6}>
              <Dropzone onDrop={handleJpgDrop} accept=".jpg">
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    <CloudUploadIcon fontSize="large" />
                    <small>Drop JPG files here</small>
                  </div>
                )}
              </Dropzone>
            </Grid>
          </Grid>

          <hr />

          <Grid container spacing={2} className="mt-3">
            <Grid item xs={12}>
              {jpgFiles && (
                <div
                  className="d-flex justify-content-between align-items-center border-danger border-bottom"
                  key={jpgFiles.name}
                >
                  <div className="d-flex align-items-center gap-5">
                    <IconButton aria-label="delete">
                      <CloseIcon />
                    </IconButton>
                    <div className="p-2">{jpgFiles.name}</div>
                  </div>
                  <div className="d-flex align-items-center gap-5">
                    <progress value={uploadProgressImage} max="100"></progress>
                    <div>{uploadProgressImage.toFixed(0)}%</div>
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              {pdfOrEpubFile && (
                <div className="d-flex justify-content-between align-items-center border-danger border-bottom">
                  <div className="d-flex align-items-center gap-5">
                    <IconButton aria-label="delete">
                      <CloseIcon />
                    </IconButton>
                    <div className="p-2">{pdfOrEpubFile.name}</div>
                  </div>
                  <div className="d-flex align-items-center gap-5">
                    <progress value={uploadProgress} max="100"></progress>
                    <div>{uploadProgress.toFixed(0)}%</div>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Button
          type="submit"
          className="p-3 w-75"
          variant="contained"
          color="primary"
          style={{ position: "relative", left: "-18px", marginTop: "20px" }}
          disabled={loading}
        >
          Editar Obra
        </Button>
      </form>
      <ToastContainer />
    </Stack>
  );
};

export default EditBookForm;
