import { Stack } from "@mui/material";
import React from "react";
import DrawerAppBar from "../components/DrawerAppBar";
import BookSalesMetricTable from "../components/BookSalesMetrics";

const SalesMetrics = () => {
  return (
    <DrawerAppBar>
      <BookSalesMetricTable />
    </DrawerAppBar>
  );
};

export default SalesMetrics;
