import * as React from "react";
import OrganizationsPage from "../components/Organization";
import DrawerAppBar from "../components/DrawerAppBar";

export default function Home() {
  return (
    <DrawerAppBar>
      <OrganizationsPage />
    </DrawerAppBar>
  );
}
